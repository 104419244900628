import { pattern as P, TrustSchemaPolicy } from "@ndn/trust-schema";

export const policy = new TrustSchemaPolicy();

policy.addPattern("rootcert", new P.ConcatPattern([
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
  new P.CertNamePattern(),
]));
policy.addPattern("sitecert", new P.ConcatPattern([
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
  new P.AlternatePattern([
    new P.VariablePattern("s1"),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
    ]),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
      new P.VariablePattern("s3"),
    ]),
  ]),
  new P.CertNamePattern(),
]));
policy.addPattern("operatorcert", new P.ConcatPattern([
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
  new P.AlternatePattern([
    new P.VariablePattern("s1"),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
    ]),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
      new P.VariablePattern("s3"),
    ]),
  ]),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.CertNamePattern(),
]));
policy.addPattern("routercert", new P.ConcatPattern([
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
  new P.AlternatePattern([
    new P.VariablePattern("s1"),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
    ]),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
      new P.VariablePattern("s3"),
    ]),
  ]),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.CertNamePattern(),
]));
policy.addPattern("lsdbdata", new P.ConcatPattern([
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
  new P.AlternatePattern([
    new P.VariablePattern("s1"),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
    ]),
    new P.ConcatPattern([
      new P.VariablePattern("s1"),
      new P.VariablePattern("s2"),
      new P.VariablePattern("s3"),
    ]),
  ]),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.VariablePattern("lsatype"),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));

policy.addRule("lsdbdata", "routercert");
policy.addRule("routercert", "operatorcert");
policy.addRule("operatorcert", "sitecert");
policy.addRule("sitecert", "rootcert");
