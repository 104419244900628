import { Certificate } from "@ndn/keychain";
import { Data, type Verifier } from "@ndn/packet";
import { Decoder } from "@ndn/tlv";
import { TrustSchema, TrustSchemaVerifier } from "@ndn/trust-schema";
import { fromHex } from "@ndn/util";

import { policy } from "./trust-policy";

// curl -fsLS -A Z https://named-data.net/ndnsec/ndn-testbed-root-x3.ndncert | xxd -p
const NDN_TESTBED_ROOT_X3_HEX = `
06fd0149072408036e646e08034b45590808ecf14c8e512315e008036e64
6e0809fd00000175e67f3210140918010219040036ee80155b3059301306
072a8648ce3d020106082a8648ce3d030107034200041b1fb763816f628d
5e49c22f803ef8950850773378cead36d5da29f2dee419f1297c674a7f61
c6a679f8feaa05f62275c94644ffb35af33ccee314fa46920cd116701b01
031c16071408036e646e08034b45590808ecf14c8e512315e0fd00fd26fd
00fe0f323032303131323054313633313337fd00ff0f3230323431323331
54323335393539fd010227fd020023fd02010866756c6c6e616d65fd0202
134e444e205465737462656420526f6f7420583317473045022100fc86bb
53ea862f4d722da5fcb834883702b078b7675086029c85e6b3a8280ada02
2053c0b1766141760e6596ba1e40712d9cf6b6a8770ea88b919a18e250d8
09c5a9`;

// curl -fsLS -A Z https://named-data.net/ndnsec/ndn-testbed-root.ndncert.2204.ndncert | xxd -p
const NDN_TESTBED_ROOT_2204_HEX = `
06fd014b072308036e646e08034b4559080827c4b22a9f7b812708036e64
6e36080000018075fa73b4140918010219040036ee80155b305930130607
2a8648ce3d020106082a8648ce3d030107034200043ee0e75b8a2ad2650b
2d3f0f0d7874cee060fb4489d723c2f398f295453e860c57f5f0cdca3972
90baef6f50fa2104495891cc2967ba4c928f5211a0393eb9f216721b0103
1c16071408036e646e08034b4559080827c4b22a9f7b8127fd00fd26fd00
fe0f323032323034323954313533393530fd00ff0f323032363132333154
323335393539fd010229fd020025fd02010866756c6c6e616d65fd020215
4e444e205465737462656420526f6f74203232303417483046022100f614
3a335a91d7c31a1e63f5d70218ecfe21ed4caa8004b2333d3c450459b9ea
022100a6ed2b83d1802b52cd1318cb60017aa95829590814f9ab4f9fadc6
b6eb9bab28`;

// base64 -d certs/root.ndncert | xxd -p
const YOURSUNNY_ROOT_20220417_HEX = `
06fd013f072f0809796f757273756e6e7908034b4559080831f670190362
c01c0809796f757273756e6e793608000001803a110ab414091801021904
0036ee80155b3059301306072a8648ce3d020106082a8648ce3d03010703
42000434b85e8a9570587900ba602acfcf7e3dd62a4b3e54416df9ba4dfd
e1aff94454b6e7289041eacf8fc6c8289ca1a7a3e4665ef01cfaf1404318
9b8b54328472fd165b1b01031c2c072a0809796f757273756e6e7908034b
4559080831f670190362c01c080473656c663608000001803a110338fd00
fd26fd00fe0f323032323034313854303030303030fd00ff0f3230323730
3431385432333539353917473045022100ff6ab9bf93ea7a1d31c2178c2c
3de7559ad606c39949b2fb81a2009f0697aed20220343e13ebaf44741246
d219a913d24014fe514750a6ea19398b37649b4cf0e6a5`;

function importRootCert(hex: string): Certificate {
  const data = Decoder.decode(fromHex(hex.replaceAll(/\s+/g, "")), Data);
  return Certificate.fromData(data);
}

const trustAnchors = [
  NDN_TESTBED_ROOT_X3_HEX,
  NDN_TESTBED_ROOT_2204_HEX,
  YOURSUNNY_ROOT_20220417_HEX,
].map(importRootCert);
const schema = new TrustSchema(policy, trustAnchors);
export const verifier: Verifier = new TrustSchemaVerifier({ schema });
