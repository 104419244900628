import { pattern as P, TrustSchemaPolicy } from "@ndn/trust-schema";

export const policy = new TrustSchemaPolicy();

policy.addPattern("#CERT", new P.ConcatPattern([
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_10"),
  new P.VariablePattern("_TAG_11"),
  new P.VariablePattern("_TAG_12"),
]));
policy.addPattern("#sitename1", new P.VariablePattern("s1"));
policy.addPattern("#sitename2", new P.ConcatPattern([
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
]));
policy.addPattern("#sitename3", new P.ConcatPattern([
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.VariablePattern("s3"),
]));
policy.addPattern("#network", new P.OverlapPattern([
  new P.VariablePattern("network"),
  new P.AlternatePattern([
    new P.ConstPattern("/8=ndn"),
    new P.ConstPattern("/8=yoursunny"),
  ]),
]));
policy.addPattern("#rootcert", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_13"),
  new P.VariablePattern("_TAG_14"),
  new P.VariablePattern("_TAG_15"),
]));
policy.addPattern("#sitecert1", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_16"),
  new P.VariablePattern("_TAG_17"),
  new P.VariablePattern("_TAG_18"),
]));
policy.addPattern("#routername1", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
]));
policy.addPattern("#routercert1", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_19"),
  new P.VariablePattern("_TAG_20"),
  new P.VariablePattern("_TAG_21"),
]));
policy.addPattern("#operatorcert1", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_22"),
  new P.VariablePattern("_TAG_23"),
  new P.VariablePattern("_TAG_24"),
]));
policy.addPattern("#lsdbdata1", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.VariablePattern("lsatype"),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));
policy.addPattern("#sitecert2", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_25"),
  new P.VariablePattern("_TAG_26"),
  new P.VariablePattern("_TAG_27"),
]));
policy.addPattern("#routername2", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
]));
policy.addPattern("#routercert2", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_28"),
  new P.VariablePattern("_TAG_29"),
  new P.VariablePattern("_TAG_30"),
]));
policy.addPattern("#operatorcert2", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_31"),
  new P.VariablePattern("_TAG_32"),
  new P.VariablePattern("_TAG_33"),
]));
policy.addPattern("#lsdbdata2", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.VariablePattern("lsatype"),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));
policy.addPattern("#sitecert3", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.VariablePattern("s3"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_34"),
  new P.VariablePattern("_TAG_35"),
  new P.VariablePattern("_TAG_36"),
]));
policy.addPattern("#routername3", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.VariablePattern("s3"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
]));
policy.addPattern("#routercert3", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.VariablePattern("s3"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_37"),
  new P.VariablePattern("_TAG_38"),
  new P.VariablePattern("_TAG_39"),
]));
policy.addPattern("#operatorcert3", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.VariablePattern("s3"),
  new P.ConstPattern("/8=%C1.Operator"),
  new P.VariablePattern("opid"),
  new P.ConstPattern("/8=KEY"),
  new P.VariablePattern("_TAG_40"),
  new P.VariablePattern("_TAG_41"),
  new P.VariablePattern("_TAG_42"),
]));
policy.addPattern("#lsdbdata3", new P.ConcatPattern([
  new P.OverlapPattern([
    new P.VariablePattern("network"),
    new P.AlternatePattern([
      new P.ConstPattern("/8=ndn"),
      new P.ConstPattern("/8=yoursunny"),
    ]),
  ]),
  new P.VariablePattern("s1"),
  new P.VariablePattern("s2"),
  new P.VariablePattern("s3"),
  new P.ConstPattern("/8=%C1.Router"),
  new P.VariablePattern("routerid"),
  new P.ConstPattern("/8=nlsr/8=lsdb"),
  new P.VariablePattern("lsatype"),
  new P.VariablePattern("version"),
  new P.VariablePattern("segment"),
]));

policy.addRule("#sitecert1", "#rootcert");
policy.addRule("#routercert1", "#operatorcert1");
policy.addRule("#lsdbdata1", "#routercert1");
policy.addRule("#operatorcert1", "#sitecert1");
policy.addRule("#sitecert2", "#rootcert");
policy.addRule("#routercert2", "#operatorcert2");
policy.addRule("#lsdbdata2", "#routercert2");
policy.addRule("#operatorcert2", "#sitecert2");
policy.addRule("#sitecert3", "#rootcert");
policy.addRule("#routercert3", "#operatorcert3");
policy.addRule("#lsdbdata3", "#routercert3");
policy.addRule("#operatorcert3", "#sitecert3");
